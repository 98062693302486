import React from 'react'
import Dashboard from '../../views/Dashboard'
import logo from '../../../images/logo_amber.png'

const Welcome: React.FC = () => {
  return (
    <Dashboard activePage="welcome">
      <div className="main__container">
        <div className="main__wrapper">
          <div className="welcome__wrapper">
            <img src={logo} alt="Amber expo" />
            <h1>AmberExpo - Twoja aplikacja webowa.</h1>
            <p className="welcome__p">
              Znajdziesz tutaj wszystkie informacje o naszych wydarzeniach. 10 - 11 października
              2023 BaltExpo.
            </p>
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default Welcome
